<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn  color="primary" rounded
                    dark
                    v-bind="attrs"
                    v-on="on"
            >
                {{$t('other.' + other.wlcm)}}
            </v-btn>
        </template>
        <v-list>
            <v-list-item :to="{name: dashboardLink}">
                <v-list-item-title>{{$t('other.' + other.dbrd)}}</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'dashboard.personalization'}" v-if="$auth.user.primary_role_name !== 'admin'">
                <v-list-item-title>{{$t('other.' + other.pprfl)}}</v-list-item-title>
            </v-list-item>
            <v-list-item  @click="handleLogout">
                <v-list-item-title class="cursor-pointer">{{$t('other.' + other.lgt)}}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import other from "@/configs/other";

    export default {
        name: "auth-user-dropdown",
        computed: {
            dashboardLink() {
              return this.$auth.user().primary_role_name === 'admin' ? 'admin.index' : 'dashboard.index'
            },
            auth() {
                return this.$auth
            }
        },
        data: function () {
            return {
                other
            }
        },
        methods: {
            handleLogout() {
               this.$auth.logout()
               this.$router.push({name: 'login'})
            },
        }
    }
</script>

<style scoped>

</style>
