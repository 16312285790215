<template>
    <v-app-bar
        app
        color="primary"
        dark
        clipped-left
    >
        <v-app-bar-nav-icon @click="$emit('toggleDrawer')"
                            v-if="isDashboard"
        />
        <router-link :to="{name: 'home'}">
            <div class="d-flex align-center">
                <v-img
                    alt="Vuetify Logo"
                    class="shrink mr-2"
                    contain
                    src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
                    transition="scale-transition"
                    width="30"
                />
                <h3 class="white--text">
                    {{appName}}
                </h3>
                <div
                class="ml-5"
                >
                    <v-btn text :to="{name: 'dashboard.billing'}" @click="setLocale('ru')"><flag iso="ru"></flag> RUS </v-btn>
                </div>
                <div
                class="ml-2"
                >
                    <v-btn text :to="{name: 'dashboard.billing'}" @click="setLocale('en')"><flag iso="gb"></flag> ENG </v-btn>
                </div>

            </div>
        </router-link>

        <v-spacer></v-spacer>

        <auth-user-dropdown v-if="$auth.user()" />
        <v-btn text :to="{name: 'login'}" v-else>{{$t('other.' + other.btnlg)}}</v-btn>
    </v-app-bar>
</template>

<script>
    import AuthUserDropdown from "./AuthUserDropdown";
    import other from "@/configs/other";


    export default {
        name: "top-menu",
        props: {
            isDashboard: {
                default: false,
                type: Boolean,
                other
            }
        },
        computed: {
            appName() {
                return process.env.VUE_APP_APP_NAME ? process.env.VUE_APP_APP_NAME : "Saas"
            }
        },
        components: {AuthUserDropdown},
        data: function () {
            return {}
        },
        methods: {
            setLocale(locale){
                this.$i18n.locale = locale;
            }
        }
    }
</script>

<style scoped>

</style>
